import React from 'react'
import './we-give.scss'
import ReactTable from 'react-table'
import '../../node_modules/react-table/react-table.css'
import { PriortyFunds, UWPriortyFunds, Funds } from './FundsData'
import SVGInline from 'react-svg-inline'
import AddIcon from '../shared/assets/add.svg'
import CircleCheck from '../shared/assets/check_circle.svg'
import LoadingElement from '../shared/components/LoadingElement'
import WeGiveForm from './components/WeGiveForm.jsx'

const workSiteInstructions = 'For associates at care sites newer to our OhioHealth family, please select your location to ensure your payroll deduction is properly processed.'
const perPayDeductionNote = '** Reminder: Your pledge will be renewed annually until you opt out.'
const perPayDeductionDate = new Date(2025, 0)
const oneTimeDeductionDate = new Date(2025, 2, 7)
const deductionsCount = 26

const WeGiveApp = class extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      selectedCharity: 0,
      deductionType: null,
      amountText: 'Amount',
      ohPledge: 0,
      uwPledge: 0,
      totalPledge: 0,
      paymentBreakdown: 0,
      preferedEmail: '',
      email: '',
      extraOHFFunds: 1,
      extraUWFunds: 1,
      workSite: null,
      selectedPriortyFund: '',
      selectedPriortyUWFund: '',
      ohfDesignations: [this.emptyDesignation(0), this.emptyDesignation(1)],
      uwDesignations: [this.emptyDesignation(0), this.emptyDesignation(1), this.emptyDesignation(2)],
      currentlyOpenedTable: 0,
      scrollTop: 0,
      valid: false,
      pristine: true,
      errors: [],
      retries: 0
    }

    this.combineFundData = (funds) => {
      let combinedArray = []
      funds.map((fund) => {
        let ids = []
        this.state.ohfDesignations.map(selectedFund => ids.push(selectedFund.fundId))
        if (!ids.includes(fund.fundId)) {
          combinedArray.push({
            'fundName': `${fund.fundName} - #${fund.fundId}`
          })
        }
      })
      return combinedArray.sort((a, b) => a.fundName > b.fundName ? 1 : -1)
    }

    this.columns = [{
      Header: props => <div className='table-header'>
                OhioHealth Funds
        <div className='close-lookup-table-btn' onClick={() => this.closeFundLookupTable()}><SVGInline className='remove-icon' svg={AddIcon} /></div>
      </div>,
      Filter: ({ filter, onChange }) => (
        <input type='text' className={'data-table-filter'}
          ref={filterInput => { this.filterInput = filterInput }}
          placeHolder='Search Funds'
          onChange={event => onChange(event.target.value)} value={filter ? filter.value : ''} />),
      filterMethod: (filter, row) => {
        let rowValues = row[filter.id].replace(/-|#/g, '').toLowerCase().trim().split(' ')
        let filterValues = filter.value.replace(/-|#/g, '').toLowerCase().trim().split(' ')
        return rowValues.some((word) => filterValues.some((filterValue) => word.startsWith(filterValue)))
      },
      accessor: 'fundName',
      Cell: props => <div className='look-up-cell' onClick={() => { this.closeFundLookupTable(props.value) }}>{props.value}</div>
    }]
  }

  emptyDesignation (index) {
    return {
      'index': index,
      'fundName': null,
      'fundId': null,
      'amount': 0,
      'err': null
    }
  }

  openFundLookupTable (inputId) {
    if (this.state.currentlyOpenedTable === 0) {
      this.setState({
        currentlyOpenedTable: inputId,
        scrollTop: $(document).scrollTop()
      })
    }
    let body = document.getElementsByTagName('BODY')[0]
    body.classList.add('table-open')
  }

  closeFundLookupTable (fundName) {
    if (fundName) {
      let fund = this.getOHFFund(fundName)
      this.addOHFDesignation(fund.fundName, fund.fundId, this.state.currentlyOpenedTable)
    }
    this.setState({ currentlyOpenedTable: 0 })
    let body = document.getElementsByTagName('BODY')[0]
    body.classList.remove('table-open')
    window.scroll(0, this.state.scrollTop)
  }

  getOHFFund (fundName) {
    return OhioHealthFoundationFunds.find(obj => {
      return obj.fundId === fundName.split('#')[1]
    })
  }

  addOHFDesignation (fundName, fundId, index) {
    let newDesignations = this.state.ohfDesignations.slice()
    if (newDesignations[index]) {
      newDesignations[index].fundName = fundName
      newDesignations[index].fundId = fundId
      newDesignations[index].index = index
    } else {
      let newDesignation = this.emptyDesignation(index)
      newDesignation.fundName = fundName
      newDesignation.fundId = fundId
      newDesignation.index = index
      newDesignations[index] = newDesignation
    }
    this.setState({ ohfDesignations: newDesignations })

    if (!this.state.pristine) { this.validate() }
  }

  fundAmountChange (event) {
    let fieldInput = (event.target.value)
    let amount = fieldInput.replace(/[^0-9.]/g, '')
    let ids = Array.from(event.target.classList)[0].split('-amount-')

    if (amount < 0 || amount === '') {
      amount = 0
    }

    if (amount >= 0) {
      if (ids[0] === 'ohf') {
        this.addOHFFundAmount(amount, ids[1])
      } else if (ids[0] === 'uw') {
        this.addUWFundAmount(amount, ids[1])
      }

      if (!this.state.pristine) { this.validate() }
    }
  }

  addOHFFundAmount (amount, index) {
    let newDesignations = this.state.ohfDesignations.slice()
    if (newDesignations[index]) {
      newDesignations[index].amount = amount
    } else {
      let newDesignation = this.emptyDesignation(index)
      newDesignation.amount = amount
      newDesignations[index].amount = newDesignation
    }
    this.setState({ ohfDesignations: newDesignations })
    this.updateTotals()
  }

  addUWFundAmount (amount, index) {
    this.setState((prevState) => {
      let newDesignations = prevState.uwDesignations.slice()
      newDesignations[index].amount = amount
      return { uwDesignations: newDesignations }
    })
    this.updateTotals()
  }

  addUWFundName (fundName, index) {
    this.setState((prevState) => {
      let newDesignations = prevState.uwDesignations.slice()
      newDesignations[index].fundName = fundName
      return { uwDesignations: newDesignations }
    })
    if (!this.state.pristine) { this.validate() }
  }

  addUWEIN (ein, index) {
    this.setState((prevState) => {
      let newDesignations = prevState.uwDesignations.slice()
      newDesignations[index].fundId = ein
      return { uwDesignations: newDesignations }
    })
    if (!this.state.pristine && ein && ein.length === 10) { this.validate() }
  }

  addOHFFundError (errors, index) {
    this.setState((prevState) => {
      let newDesignations = prevState.ohfDesignations.slice()
      newDesignations[index].err = errors
      return { ohfDesignations: newDesignations }
    })
  }

  removeOHFError (index) {
    this.setState((prevState) => {
      let newDesignations = prevState.ohfDesignations.slice()
      newDesignations[index].err = null
      return { ohfDesignations: newDesignations }
    })
  }

  addUWFundError (errors, index) {
    this.setState((prevState) => {
      let newDesignations = prevState.uwDesignations.slice()
      newDesignations[index].err = errors
      return { uwDesignations: newDesignations }
    })
  }

  removeUWError (index) {
    this.setState((prevState) => {
      let newDesignations = prevState.uwDesignations.slice()
      newDesignations[index].err = null
      return { uwDesignations: newDesignations }
    })
  }

  getNameError (charityType, index) {
    let error
    if (charityType === 'ohf') {
      error = this.state.ohfDesignations[index].err && this.state.ohfDesignations[index].err[0].length ? this.state.ohfDesignations[index].err[0] : ''
    } else {
      error = this.state.uwDesignations[index].err && this.state.uwDesignations[index].err[0].length ? this.state.uwDesignations[index].err[0] : ''
    }
    return error
  }

  getAmountError (charityType, index) {
    let error
    if (charityType === 'ohf') {
      error = this.state.ohfDesignations[index].err && this.state.ohfDesignations[index].err[1].length ? this.state.ohfDesignations[index].err[1] : ''
    } else {
      error = this.state.uwDesignations[index].err && this.state.uwDesignations[index].err[1].length ? this.state.uwDesignations[index].err[1] : ''
    }
    return error
  }

  getIdError (index) {
    return this.state.uwDesignations[index].err && this.state.uwDesignations[index].err[2].length ? this.state.uwDesignations[index].err[2] : ''
  }

  removeOHFDesignation (indexToRemove) {
    if (indexToRemove === 0) {
      this.setState({ selectedPriortyFund: '' })
    }

    if (this.state.ohfDesignations[indexToRemove]) {
      let newDesignations = []

      if (this.state.ohfDesignations.length <= 2) {
        if (indexToRemove === 0) {
          newDesignations.push(this.emptyDesignation(0))
          newDesignations.push(this.state.ohfDesignations[1])
        } else {
          newDesignations.push(this.state.ohfDesignations[0])
          newDesignations.push(this.emptyDesignation(1))
        }
      } else {
        if (indexToRemove === 0) {
          newDesignations.push(this.emptyDesignation(0))
          this.state.ohfDesignations.map((designation, index) => {
            if (index !== 0) {
              newDesignations.push(designation)
            }
          })
        } else {
          for (let i = 0; i < this.state.ohfDesignations.length - 1; i++) {
            if (indexToRemove > i) {
              newDesignations.push(Object.assign({}, this.state.ohfDesignations[i]))
            } else {
              newDesignations.push(Object.assign({}, this.state.ohfDesignations[i + 1]))
            }
          }
        }
      }
      this.setState({ ohfDesignations: newDesignations })
    }
    this.updateTotals()
  }

  removeUWDesignationAndAmount (indexToRemove) {
    switch (indexToRemove) {
      case 0:
        this.setState({ selectedPriortyUWFund: '' })
        this.setState((prevState) => {
          let newDesignations = prevState.uwDesignations.slice()
          newDesignations[0] = this.emptyDesignation(0)
          return { uwDesignations: newDesignations }
        })
        break
      case 1:
        this.setState({ extraUWFunds: 1 })
        this.setState((prevState) => {
          let newDesignations = prevState.uwDesignations.slice()
          if (newDesignations[2].fundName || newDesignations[2].fundId || newDesignations[2].amount > 0) {
            let designationCopy = Object.assign({}, newDesignations[2])
            newDesignations[1] = designationCopy
            newDesignations[2] = this.emptyDesignation(2)
          } else {
            newDesignations[1] = this.emptyDesignation(1)
            newDesignations[2] = this.emptyDesignation(2)
          }
          return { uwDesignations: newDesignations }
        })
        break
      case 2:
        this.setState({ extraUWFunds: 1 })
        this.setState((prevState) => {
          let newDesignations = prevState.uwDesignations.slice()
          newDesignations[2] = this.emptyDesignation(2)
          return { uwDesignations: newDesignations }
        })
        break
      default:
    }

    this.updateTotals()
  }

  updateTotals () {
    let ohfTotal = 0
    let uwTotal = 0
    let totalPledge = 0
    let paymentBreakdown = 0
    this.state.ohfDesignations.map((designation) => {
      ohfTotal = designation.amount ? (parseFloat(ohfTotal) + parseFloat(designation.amount)).toFixed(2) : ohfTotal
    })
    this.state.uwDesignations.map((designation) => {
      uwTotal = designation.amount ? (parseFloat(uwTotal) + parseFloat(designation.amount)).toFixed(2) : uwTotal
    })
    totalPledge = (parseFloat(ohfTotal) + parseFloat(uwTotal)).toFixed(2)
    paymentBreakdown = this.state.deductionType !== 'single' ? parseFloat(totalPledge / deductionsCount).toFixed(2) : totalPledge
    this.setState({
      ohPledge: ohfTotal,
      uwPledge: uwTotal,
      totalPledge: totalPledge,
      paymentBreakdown: paymentBreakdown
    })
  }

  deductionTypChange (e) {
    this.setState({
      deductionType: e.currentTarget.value
    }, this.updateTotals)
  }

  handleWorkSiteChange (e) {
    this.setState({
      workSite: e.currentTarget.value
    })
  }

  ohfpiorityFundChange (e) {
    let fund = this.getOHFFund(e.currentTarget.value)
    this.setState({ selectedPriortyFund: fund.fundName })
    this.addOHFDesignation(fund.fundName, fund.fundId, 0)
  }

  uwpiorityFundChange (e) {
    let fund = UWPriortyFunds.find(obj => {
      return obj.fundId === e.currentTarget.value.split('#')[1]
    })
    this.setState({ selectedPriortyUWFund: fund.fundName })
    this.addUWFundName(fund.fundName, 0)
    this.addUWEIN(fund.fundId, 0)
  }

  removeOHFFund (index) {
    if (this.state.extraOHFFunds > 1 && index !== 0) {
      this.setState(prevState => ({
        extraOHFFunds: (prevState.extraOHFFunds - 1)
      }))
    }
    this.removeOHFDesignation(index)
  }

  removeUWFund (index) {
    this.removeUWDesignationAndAmount(index)
  }

  renderPledgeSummaryArea () {
    return <div className='pledge-summary-area'>
      <div className='pledge-summary-title'>Your pledge summary</div>
      {this.renderPledgeSummary()}
      {this.renderEmailSummary()}
      {this.renderErrorMEssages()}
      {this.props.submitting
        ? <div className='loader-overlay'><LoadingElement /></div>
        : <button onClick={() => this.submitData()} className='btn btn-primary'>MAKE MY PLEDGE</button>
      }
    </div>
  }

  fundSort (sortAscending = true, usePriority) {
      return (a, b) => {
          if (a === b) {
              return 0;
          }

          if (a === null || a === undefined) {
              return 1
          }

          if (b === null || b === undefined) {
              return -1
          }

          if (sortAscending) {
              return a < b ? -1 : 1
          }

          return a < b ? 1 : -1
      }
  }

    priorityFundSort(sortAscending = true) {
        return (a, b) => {
            if (a.priorityFundInfo.sortOrder === b.priorityFundInfo.sortOrder) {
                return a.fundName < b.fundName ?
                    -1 :
                    a.fundName > b.fundName ? 
                        1 : 0;
            }

            if (a.priorityFundInfo.sortOrder === null || a.priorityFundInfo.sortOrder === undefined) {
                return 1
            }

            if (b.priorityFundInfo.sortOrder === null || b.priorityFundInfo.sortOrder === undefined) {
                return -1
            }

            if (sortAscending) {
                return a.priorityFundInfo.sortOrder < b.priorityFundInfo.sortOrder ? -1 : 1
            }

            return a.priorityFundInfo.sortOrder < b.priorityFundInfo.sortOrder ? 1 : -1
        }
    }

  renderPledgeSummary () {
    let single = this.state.deductionType === 'single'
    let charity = this.state.selectedCharity

    return <div className='donation-summary-section'>
      {(this.state.selectedCharity === 1 || this.state.selectedCharity === 3) &&
      <div className='donation-summary-container'>
        <div className='charity-title'>OhioHealth Foundation</div>
        <div className={`designations ${this.state.selectedCharity === 3 ? 'no-border' : ''}`}>
          {this.state.ohfDesignations.map((designation, index) => (designation.amount > 0 && designation.fundName ? this.renderDesignationRow(designation, index) : ''))}
        </div>
      </div>
      }
      {(this.state.selectedCharity === 2 || this.state.selectedCharity === 3) &&
      <div className='donation-summary-container'>
        <div className='charity-title'>United Way</div>
        <div className='designations'>
          {this.state.uwDesignations.map((designation, index) => (designation.amount > 0 && designation.fundName && designation.fundId ? this.renderDesignationRow(designation, index) : ''))}
        </div>
      </div>
      }
      <div className='total-pledge-container summary-talley-row'>
        <div className='pledge-col-1 bold'>Total WE GIVE pledge:</div>
        <div className='pledge-col-2 money-font'>{`$${parseFloat(this.state.totalPledge).toFixed(2)}`}</div>
      </div>
      <div className='deductions-info'>
        {this.getDeductionsInfoText(charity)}
      </div>
      {this.state.deductionType != null &&
      <div className='split-or-single'>
        <div className='summary-talley-row'>
          <div className='pledge-col-1'>
            {
              single
                ? <span>{`Single payroll deduction made on ${oneTimeDeductionDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: '2-digit' })}`}:</span>
                : <span>{`Deduction each pay period of ${perPayDeductionDate.getFullYear()}`}:</span>
            }
          </div>
          <div className='pledge-col-2 money-font'>
            {
              single
                ? `$${parseFloat(this.state.totalPledge).toFixed(2)}`
                : `$${(this.state.totalPledge / deductionsCount).toFixed(2)}`
            }
          </div>
        </div>
        {
          !single && <span>{`${perPayDeductionNote}`}</span>
        }
      </div>
      }
    </div>
  }

  renderDesignationRow (designation, index) {
    return <div className='summary-talley-row designation-summary'>
      <div className='pledge-col-1'>{designation.fundName}:</div>
      <div className='pledge-col-2 money-font'>{`$${parseFloat(designation.amount).toFixed(2)}`}</div>
    </div>
  }

  getDeductionsInfoText (charity) {
    let text = 'Deductions will appear on your payslip as '
    if ((charity === 1 || charity === 3)) {
      text += 'FND (OhioHealth Foundation)'
    }
    if (charity === 3) {
      text += ' and '
    }
    if (charity === 2 || charity === 3) {
      text += ' UW (United Way)'
    }
    text += '.'
    return text
  }

  renderEmailSummary () {
    return <div className='email-summary-section'>
      <div className='email-summary-title'>
                A pledge confirmation will be sent to your OhioHealth email address.
      </div>
      <div className='email-summary-text'>
                If you would like your pledge confirmation sent to a different email address, please enter it here:
      </div>
      <input className={`${this.state.errors.includes('Email is not valid') ? 'input-error' : ''}`}
        placeholder='Email address' type='email' name='email'
        value={this.state.preferedEmail} onBlur={this.updatePreferedEmail.bind(this)} />
    </div>
  }

  updatePreferedEmail (e) {
    this.setState({ preferedEmail: e.currentTarget.value })
    if (!this.state.pristine) { this.validate() }
  }

  renderErrorMEssages () {
    return <div className='error-message-container'>
      {this.state.errors.length ? <div className='error-heading'>Please correct the following errors:</div> : ''}
      <ul>
        {this.state.errors.map((error) => {
          return <li className='field__error'>{error}</li>
        })}
      </ul>
    </div>
  }

  renderQuestionsLink () {
    return <div className='questions-container'>
      <span class='question-icon'>?</span> Questions: Email <b><a href='mailto:wegive@ohiohealth.com'>WEGive@OhioHealth.com</a></b>
    </div>
  }

  renderHeading () {
    let title = ''
    if (this.state.selectedCharity === 1) {
      title = 'OhioHealth Foundation payroll deduction'
    } else if (this.state.selectedCharity === 2) {
      title = 'United Way payroll deduction'
    } else if (this.state.selectedCharity === 3) {
      title = 'OhioHealth Foundation and United Way payroll deduction'
    }

    return <div className='heading-container'>
      <h1 className='heading'>{title}</h1>
      <hr />
    </div>
  }

  renderDeductionType () {
    return <div className='deduction-area'>
      <div className='subheading'>
        How would you like to make your pledge?
        <div className='instruction'>(choose one...)</div>
      </div>

      <div className='radio-buttons'>
        <div className='split-deduction radio-button'>
          <input type='radio' name='address' value={'split'} checked={this.state.deductionType === 'split'} onChange={this.deductionTypChange.bind(this)} />
          <div className='radio-btn-label'>
            <span className='radio-button-heading'>Per Pay Deduction</span>
            <span className='radio-button-subheading'>({`made each pay period starting in ${perPayDeductionDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long' })}`})</span>
            <div className='instruction'>{`${perPayDeductionNote}`}</div>
          </div>
        </div>
        <div className='single-deduction radio-button'>
          <input type='radio' name='address' value={'single'} checked={this.state.deductionType === 'single'} onChange={this.deductionTypChange.bind(this)} />
          <div className='radio-btn-label'>
            <span className='radio-button-heading'>One-Time Deduction </span>
            <span className='radio-button-subheading'>({`made on ${oneTimeDeductionDate.toLocaleDateString('en-us', { year: 'numeric', month: 'long', day: '2-digit' })}`})</span>
          </div>
        </div>
      </div>
    </div>
  }

  renderOHFDesignationsArea () {
    return <div className='designation-area'>
      <div className='subheading'>Choose your designations</div>
      <div className='designation-selection-area'>
        <div className='designation-row col-heading'>
          <div className='col-1'>OhioHealth Foundation funds</div>
          <div className='col-2 amount-label'>Total Annual Amount</div>
        </div>
        {this.renderFoundationPriortyFunds()}
        <div className='andor'>and/or</div>
        {this.renderExtraFoundationFunds()}
        {this.renderAddAnotherOHFLink()}
      </div>
    </div>
  }

  renderWorkSiteDesignationArea () {
    return <div className='workSiteDesignationArea'>
      <div className='instruction'>{`${workSiteInstructions}`}</div>
      <div className='checkboxGroup'>
        <div className='checkboxPair'>
          <input id='workSite_vanWert' type='checkbox' name='workSite' value='vanWert' checked={this.state.workSite === 'vanWert'} onChange={this.handleWorkSiteChange.bind(this)} />
          <label htmlFor='workSite_vanWert'>OhioHealth Van Wert Hospital</label>
        </div>
        <div className='checkboxPair'>
          <input id='workSite_sormc' type='checkbox' name='workSite' value='sormc' checked={this.state.workSite === 'sormc'} onChange={this.handleWorkSiteChange.bind(this)} />
          <label htmlFor='workSite_sormc'>OhioHealth Southeastern Medical Center</label>
        </div>
      </div>
    </div>
  }

  renderFoundationPriortyFunds () {
    let nameError = !this.state.pristine && !this.state.valid ? this.getNameError('ohf', 0) : ''
    let amountError = !this.state.pristine && !this.state.valid ? this.getAmountError('ohf', 0) : ''
    return <div className='designation-row'>
      <div className='designation-name-container'>
        <div className='mobile-label'>OhioHealth Foundation funds (1)</div>
        <select className={`col-1 ${nameError.length ? 'input-error' : ''}`} onChange={this.ohfpiorityFundChange.bind(this)}>
          <option className='default' value='' disabled selected={this.state.selectedPriortyFund === ''}>Select an OhioHealth Systemwide fund</option>
          {OhioHealthFoundationFunds.filter(fund => fund.priorityFundInfo.isPriorityFund).sort(this.priorityFundSort()).map(fund => {
              let fundName = `${fund.fundName} - #${fund.fundId}`

              return <option selected={fund.fundName === this.state.selectedPriortyFund} value={fundName}>{fund.fundName}</option>
          })}
        </select>
        {nameError.length ? <div className='field__error'>{nameError}</div> : ''}
      </div>
      <div className='designation-amount-container'>
        <div className='mobile-label'>Total Annual Amount</div>
        <div className='number-input-container'><div className='money-font'>$</div>
          <input type='text' placeholder='0.00' name='currency-input' pattern='[0-9]+'
            onBlur={(e) => this.fundAmountChange(e)}
            value={this.state.ohfDesignations[0].amount > 0 ? this.state.ohfDesignations[0].amount : null}
            className={`ohf-amount-0 ${amountError.length ? 'input-error' : ''}`}
            onChange={(e) => this.fundAmountChange(e)}
          />
        </div>
        {amountError.length ? <div className='field__error amount-error'>{amountError}</div> : ''}
      </div>
      {(this.state.selectedPriortyFund !== '' || this.state.ohfDesignations[0].amount > 0) &&
      <div className='remove-fund-btn' onClick={() => this.removeOHFFund(0)}>
        <SVGInline className='remove-icon' svg={AddIcon} /> <span className='underline-link'>Remove Fund</span>
      </div>
      }
      <hr className='mobile-hr' />
    </div>
  }

  renderExtraFoundationFunds () {
    let designationInputs = []
    for (let i = 0; i < this.state.extraOHFFunds; i++) {
      let count = i + 1
      let nameError = !this.state.pristine && !this.state.valid ? this.getNameError('ohf', count) : ''
      let amountError = !this.state.pristine && !this.state.valid ? this.getAmountError('ohf', count) : ''
      designationInputs.push(<div className='designation-row'>
        <div className='designation-name-container'>
          <div className='mobile-label'>OhioHealth Foundation funds ({count + 1})</div>
          <div tabIndex='0' className={`fake-select col-1 ohf-designation-${count}`} onClick={() => this.openFundLookupTable(`${count}`)}>
            {this.state.ohfDesignations[count] && this.state.ohfDesignations[count].fundName
              ? this.state.ohfDesignations[count].fundName
              : 'Search for an OhioHealth fund'
            }
          </div>
          {nameError.length ? <div className='field__error'>{nameError}</div> : ''}
        </div>
        <div className='designation-amount-container'>
          <div className='mobile-label'>Total Annual Amount</div>
          <div className='number-input-container'><div className='money-font'>$</div>
            <input type='text' placeholder='0.00' name='currency-input' pattern='[0-9]+'
              onBlur={(e) => this.fundAmountChange(e)}
              value={this.state.ohfDesignations[count].amount > 0 ? this.state.ohfDesignations[count].amount : null}
              className={`ohf-amount-${count}`}
              onChange={(event) => this.fundAmountChange(event)} />
          </div>
          {amountError.length ? <div className='field__error amount-error'>{amountError}</div> : ''}
        </div>
        {(this.state.ohfDesignations[count].fundName || this.state.ohfDesignations[count].amount > 0)
          ? <div className='remove-fund-btn' onClick={() => this.removeOHFFund(count)}>
            <SVGInline className='remove-icon' svg={AddIcon} /> <span className='underline-link'>Remove Fund</span>
          </div>
          : ''
        }
        {(this.state.ohfDesignations[count].fundName || this.state.ohfDesignations[count].amount) ? <hr className='mobile-hr' /> : ''}
      </div>)
    }
    return designationInputs
  }

  addAnotherOHFFund () {
    let index = this.state.extraOHFFunds + 1
    this.setState((prevState) => {
      let newDesignations = prevState.ohfDesignations.slice()
      newDesignations.push(this.emptyDesignation(index))
      return {
        ohfDesignations: newDesignations,
        extraOHFFunds: index
      }
    })
  }

  renderAddAnotherOHFLink () {
    let designation = this.state.ohfDesignations[this.state.extraOHFFunds]
    return (designation.fundName && (designation.amount > 0))
      ? <div className='add-another-link' onClick={() => { this.addAnotherOHFFund() }}><SVGInline className='add-icon' svg={AddIcon} /> <span className='underline-link'>Add another fund</span></div>
      : ''
  }

  renderUWDesignationsArea () {
    return <div className={`designation-area uw-designation-area ${this.state.selectedCharity === 3 ? 'extra-padding-top' : ''}`}>
      <div className='subheading'>Choose your designations (two maximum)</div>
      <div className='designation-selection-area'>
        <div className='designation-row col-heading'>
          <div className='col-1'>United Way Agency</div>
          <div className='col-2 amount-label'>Total Annual Amount</div>
        </div>
        {this.renderUWPriortyFunds()}
        <div className='andor'>and/or</div>
        {this.renderUWExtraFunds()}
        {(this.state.extraUWFunds === 1 &&
                    this.state.uwDesignations[1].fundName &&
                    this.state.uwDesignations[1].amount &&
                    this.state.uwDesignations[1].fundId)
          ? <div className='add-another-link' onClick={() => { this.setState({ extraUWFunds: 2 }) }}><SVGInline className='add-icon' svg={AddIcon} /> <span className='underline-link'>Add another fund</span></div>
          : <a href='https://www.charitynavigator.org/' target='_blank' className='charity-navigator-link underline-link'>Search for funds with Charity Navigator</a>
        }
      </div>
    </div>
  }

  renderUWPriortyFunds () {
    let nameError = !this.state.pristine && !this.state.valid ? this.getNameError('uw', 0) : ''
    let amountError = !this.state.pristine && !this.state.valid ? this.getAmountError('uw', 0) : ''
    return <div className='designation-row'>
      <div className='designation-name-container'>
        <div className='mobile-label'>United Way funds (1)</div>
        <select className='col-1 uw-designation-0' onChange={this.uwpiorityFundChange.bind(this)}>
          <option className='default' value='' disabled selected={this.state.selectedPriortyUWFund === ''}>Select a United Way agency</option>
          {UWPriortyFunds.map(fund => {
            let fundName = `${fund.fundName} - #${fund.fundId}`
            return <option selected={fund.fundName === this.state.selectedPriortyUWFund} value={fundName}>{fund.fundName}</option>
          })}
        </select>
        {nameError.length ? <div className='field__error'>{nameError}</div> : ''}
      </div>
      <div className='designation-amount-container'>
        <div className='mobile-label'>Total Annual Amount</div>
        <div className='number-input-container'>
          <div className='money-font'>$</div>
          <input type='text' placeholder='0.00' name='currency-input' pattern='[0-9]+'
            onBlur={(e) => this.fundAmountChange(e)}
            value={this.state.uwDesignations[0].amount > 0 ? this.state.uwDesignations[0].amount : null}
            className={'uw-amount-0'}
            onChange={(e) => this.fundAmountChange(e)}
          />
        </div>
        {amountError.length ? <div className='field__error amount-error'>{amountError}</div> : ''}
      </div>
      {(this.state.selectedPriortyUWFund !== '' || this.state.uwDesignations[0].amount > 0) &&
      <div className='uw-priority-remove-fund-btn' onClick={() => this.removeUWFund(0)}>
        <SVGInline className='remove-icon' svg={AddIcon} /> <span className='underline-link'>Remove Agency</span>
      </div>
      }
    </div>
  }

  renderUWExtraFunds () {
    let designationInputs = []
    for (let i = 0; i < this.state.extraUWFunds; i++) {
      let count = i + 1
      let showRemoveBtn = (this.state.uwDesignations[count].fundName || this.state.uwDesignations[count].fundId || this.state.uwDesignations[count].amount > 0)
      let nameError = !this.state.pristine && !this.state.valid ? this.getNameError('uw', count) : ''
      let amountError = !this.state.pristine && !this.state.valid ? this.getAmountError('uw', count) : ''
      let einError = !this.state.pristine && !this.state.valid ? this.getIdError(count) : ''
      if (count === 2) {
        designationInputs.push(<hr />)
      }
      designationInputs.push(<div className='designation-row top-row'>
        <div className='designation-name-container'>
          <div className='mobile-label'>United Way funds ({count + 1})</div>
          <input type='text' value={this.state.uwDesignations[count].fundName} className={`col-1 uw-designation-${count}`} placeHolder='Organization Name'
            onBlur={(e) => {
              if (e.currentTarget.value) {
                this.addUWFundName(e.currentTarget.value, count)
              }
            }} />
          {nameError.length ? <div className='field__error'>{nameError}</div> : ''}
        </div>
        <div className='designation-amount-container'>
          <div className='mobile-label'>Total Annual Amount</div>
          <div className='number-input-container'>
            <div className='money-font'>$</div>
            <input type='text' placeholder='0.00' name='currency-input' pattern='[0-9]+'
              onBlur={(e) => this.fundAmountChange(e)}
              value={this.state.uwDesignations[count].amount > 0 ? this.state.uwDesignations[count].amount : null}
              className={`uw-amount-${count}`}
              onChange={(e) => this.fundAmountChange(e)}
            />
          </div>
          {amountError.length ? <div className='field__error amount-error'>{amountError}</div> : ''}
        </div>
        {showRemoveBtn
          ? <div className='remove-fund-btn' onClick={() => this.removeUWFund(count)}>
            <SVGInline className='remove-icon' svg={AddIcon} /> <span className='underline-link'>Remove Agency</span>
          </div> : ''}
      </div>)
      designationInputs.push(<div className='designation-row'>
        <div className='designation-ein-container'>
          <div className='mobile-label'>EIN</div>
          <input type='text' maxlength='10' value={this.state.uwDesignations[count].fundId} className={`col-1 uw-ein-${count}`} placeHolder='Organization EIN (ex: 00-0000000)'
            onChange={(e) => this.addUWEIN(e.currentTarget.value, count)} />
          {einError.length ? <div className='field__error'>{einError}</div> : ''}
          {showRemoveBtn
            ? <div className='remove-fund-btn remove-fund-btn-mobile' onClick={() => this.removeUWFund(count)}>
              <SVGInline className='remove-icon' svg={AddIcon} /> <span className='underline-link'>Remove Fund</span>
            </div> : ''}
        </div>
      </div>)
    }
    return designationInputs
  }

  getPledgeInfoText () {
    return this.state.deductionType === 'single' ? `Your one-time WE GIVE pledge of $${this.state.paymentBreakdown} has been submitted.` : `Your WE GIVE pledge of $${this.state.paymentBreakdown} per pay has been submitted.`
  }

  validate () {
    let isValid = true
    let siteErrors = []
    let hasAnOHFDesignation = false
    let hasAUWDesignation = false
    this.state.ohfDesignations.map((designation, index) => {
      if (this.hasValues(designation) && !this.hasAllValues(designation)) {
        isValid = false
        let designationErrors = ['', '']
        if (!designation.fundName || designation.fundName === null) { designationErrors[0] = 'Please select a fund' }
        if (designation.amount === 0) { designationErrors[1] = 'Amount missing' }
        this.addOHFFundError(designationErrors, index)
      } else {
        if (designation.err) { this.removeOHFError(index) }
      }

      if (this.hasValues(designation)) {
        hasAnOHFDesignation = true
      }
    })
    this.state.uwDesignations.map((designation, index) => {
      if (this.hasValues(designation)) {
        let designationErrors = ['', '', '']
        hasAUWDesignation = true
        if (!this.hasAllValues(designation)) {
          isValid = false
          if (designation.fundName === null) { designationErrors[0] = 'Please enter a charity name' }
          if (designation.amount === 0) { designationErrors[1] = 'Amount missing' }
          if (designation.fundId === null) { designationErrors[2] = 'Please enter a valid EIN (ex: 00-0000000)' }
        }
        if (designation.fundId !== null && !this.validateEIN(designation.fundId)) {
          isValid = false
          designationErrors[2] = 'Please enter a valid EIN (ex: 00-0000000)'
        }
        if (!isValid) {
          this.addUWFundError(designationErrors, index)
        } else {
          if (designation.err) { this.removeUWError(index) }
        }
      }
    })

    if (this.state.preferedEmail.length && !this.validateEmail(this.state.preferedEmail)) {
      isValid = false
      siteErrors.push('Email is not valid')
    }

    if (this.state.selectedCharity === 1) {
      if (!hasAnOHFDesignation) {
        isValid = false
        siteErrors.push('Please select at least 1 Charity to donate to')
      }
    }
    if (this.state.selectedCharity === 2) {
      if (!hasAUWDesignation) {
        isValid = false
        siteErrors.push('Please select at least 1 Charity to donate to')
      }
    }
    if (this.state.selectedCharity === 3) {
      if (!hasAnOHFDesignation && !hasAUWDesignation) {
        isValid = false
        siteErrors.push('Please select at least 1 Charity to donate to')
      }
    }
    if (this.state.deductionType === null) {
      isValid = false
      siteErrors.push('Please select either a One-Time or a Per Pay Period pledge.')
    }

    this.setState({
      pristine: false,
      valid: isValid,
      errors: siteErrors
    })

    return isValid
  }

  hasValues (designation) {
    return ((designation.fundName !== null && designation.fundName.length) ||
            (designation.fundId !== null && designation.fundId.length) ||
            (designation.amount > 0))
  }

  hasAllValues (designation) {
    return ((designation.fundName !== null && designation.fundName.length) &&
            (designation.fundId !== null && designation.fundId.length) &&
            (designation.amount > 0))
  }

  validateEmail (email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  validateEIN (ein) {
    const re = /^[1-9]\d?-\d{7}$/
    return re.test(ein)
  }

  submitData () {
    if (this.validate()) {
      let pledges = []
      let emailAddress = this.state.preferedEmail.length ? this.state.preferedEmail : this.props.emailAddress
      this.setState({ email: emailAddress })
      this.state.ohfDesignations.map((designation) => {
        if (this.hasAllValues(designation)) {
          pledges.push(this.convertPledgeData(designation, 'FND'))
        }
      })
      this.state.uwDesignations.map((designation) => {
        if (this.hasAllValues(designation)) {
          pledges.push(this.convertPledgeData(designation, 'UW'))
        }
      })
      let data = {
        firstName: this.props.firstName,
        lastName: this.props.lastName,
        emailAddress: emailAddress,
        opid: this.props.opid,
        totalAmount: this.state.totalPledge,
        paymentBreakdown: this.state.paymentBreakdown,
        pledges: pledges,
        workSite: this.state.workSite
      }
      this.props.postDataToDB(data)
    } else {
      if (!this.state.errors.length) {
        document.getElementsByClassName('designation-area')[0].scrollIntoView()
      }
    }
  }

  convertPledgeData (designation, type) {
    let pledge = {
      fundId: designation.fundId,
      fundName: designation.fundName,
      fundCode: type,
      donationCount: this.state.deductionType === 'single' ? 1 : deductionsCount,
      donationAmount: parseFloat(designation.amount).toFixed(2)
    }
    return pledge
  }

  resubmit () {
    if (!this.props.submitting) {
      this.props.resubmit()
      if (this.state.retries < 3) {
        setTimeout(() => this.submitData(), 5000)
      }

      this.setState((prevState) => {
        return { retries: prevState.retries + 1 }
      })
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevState.currentlyOpenedTable !== this.state.currentlyOpenedTable && this.state.currentlyOpenedTable !== 0) {
      this.filterInput.focus()
    }
    if (this.state.valid && !this.state.pristine && !this.props.submitting && this.props.postSuccess) {
      window.scrollTo(0, 0)
    }
  }

  render () {
    return (
      <div className='we-give-payroll-deduction-app'>
        {this.props.postSuccess === null && <div className='navigation-page'>
          <img className='ohiohealth-logo' src='https://www.ohiohealth.com/siteassets/global/oh_logo_blue-200x52.png' alt='ohiohealth logo' />
          <div className='welcome-message'>
            {this.props.firstName} {this.props.lastName}
          </div>
          {this.state.selectedCharity === 0 &&
          <div className='navigation-area'>
            <h2>Choose the organization your gift will support</h2>
            <div className='navigation-tiles-container'>
              {/*<button onClick={() => { this.setState({ selectedCharity: 1 }) }} className='navigation-tile'>OhioHealth Foundation</button>
              <button onClick={() => { this.setState({ selectedCharity: 2 }) }} className='navigation-tile'>United Way</button>*/}
              <button onClick={() => { this.setState({ selectedCharity: 3 }) }} className='navigation-tile'>OhioHealth Foundation<br />+<br />United Way</button>
            </div>
          </div>
          }
                {this.state.selectedCharity !== 0 &&
                    <WeGiveForm
                        selectedCharity={this.state.selectedCharity}
                        firstName={this.props.firstName}
                        lastName={this.props.lastName}
                        opid={this.props.opid}
                        emailAddress={this.props.emailAddress}
                    />
                }
          {/*{this.state.selectedCharity === 1 &&*/}
          {/*<div className='selection-area'>*/}
          {/*  {this.renderHeading()}*/}
          {/*  {this.renderDeductionType()}*/}
          {/*  {this.renderOHFDesignationsArea()}*/}
          {/*  {this.renderWorkSiteDesignationArea()}*/}
          {/*  {this.renderPledgeSummaryArea()}*/}
          {/*  {this.renderQuestionsLink()}*/}
          {/*</div>*/}
          {/*}*/}
          {/*{this.state.selectedCharity === 2 &&*/}
          {/*<div className='selection-area'>*/}
          {/*  {this.renderHeading()}*/}
          {/*  {this.renderDeductionType()}*/}
          {/*  {this.renderUWDesignationsArea()}*/}
          {/*  {this.renderWorkSiteDesignationArea()}*/}
          {/*  {this.renderPledgeSummaryArea()}*/}
          {/*  {this.renderQuestionsLink()}*/}
          {/*</div>*/}
          {/*}*/}
          {/*{this.state.selectedCharity === 3 &&*/}
          {/*<div className='selection-area'>*/}
          {/*  {this.renderHeading()}*/}
          {/*  {this.renderDeductionType()}*/}
          {/*  {this.renderOHFDesignationsArea()}*/}
          {/*  {this.renderUWDesignationsArea()}*/}
          {/*  {this.renderWorkSiteDesignationArea()}*/}
          {/*  {this.renderPledgeSummaryArea()}*/}
          {/*  {this.renderQuestionsLink()}*/}
          {/*</div>*/}
          {/*}*/}
          {/*{this.state.currentlyOpenedTable !== 0 &&*/}
          {/*<div className='modal shown-modal'>*/}
          {/*  <div className='background'>*/}
          {/*    <div className='modal-content'>*/}
          {/*      <div className='fund-lookup-table'>*/}
          {/*        <ReactTable*/}
          {/*          filterable*/}
          {/*          showPagination={false}*/}
          {/*          defaultPageSize={Funds.length}*/}
          {/*          resizable={false}*/}
          {/*          data={this.combineFundData(Funds)}*/}
          {/*          columns={this.columns}*/}
          {/*          className={'-striped'}*/}
          {/*        />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*}*/}
        </div>}
        {this.props.postSuccess === true &&
        <div className='success-page'>
          <img className='ohiohealth-logo' src='https://www.ohiohealth.com/siteassets/global/oh_logo_blue-200x52.png' alt='ohiohealth logo' />
          <div className='light-blue-container'>
            <div className='big-icon'><SVGInline className='circle-check' svg={CircleCheck} /></div>
            <h2>Thank you, {this.props.firstName} {this.props.lastName}.</h2>
            <p>Your support makes amazing things possible, close to home and close to our hearts.</p>
            <div className='pledge-info-text'>{this.getPledgeInfoText()}</div>
            <div className='pledge-confirmation-text'>A pledge confirmation for your records has been sent to {this.state.email}</div>
            <div className='no-email'>Didn't receive your email?</div>
            <div className='contact-us'>Contact us: <b><a href='mailto:wegive@ohiohealth.com'>WEGive@OhioHealth.com</a></b></div>
          </div>
          <img className='we-give-logo' src='https://www.ohiohealth.com/siteassets/campaigns/we-give/2021_wegive_graphic.jpg' alt='We Give Logo' />
        </div>
        }
        {this.props.postSuccess === false &&
        <div className='failure-page'>
          <img className='ohiohealth-logo' src='https://www.ohiohealth.com/siteassets/global/oh_logo_blue-200x52.png' alt='ohiohealth logo' />
          <div className='light-blue-container'>
            <div className='big-icon rotate-icon'><SVGInline className='remove-icon' svg={AddIcon} /></div>
            <h2>Sorry, {this.props.firstName} {this.props.lastName}.</h2>
            <p>An error prevented your pledge commitment from being properly completed.</p>
            <div className='pledge-info-text'>Your WE GIVE pledge of {this.state.totalPledge} has NOT been submitted.</div>
            <div className='no-email'>You can try to resubmit your pledge, or contact our support team by email.</div>
            {this.props.submitting
              ? <div className='loader-overlay'><LoadingElement /></div>
              : (this.state.retries < 1
                ? <div className='btn btn-primary' onClick={() => this.resubmit()}>RESUBMIT</div>
                : <div className='retry-failed'>Retry failed. Please contact our support team.</div>)
            }
            <div className='contact-us'>Contact us: <a href='mailto:wegive@ohiohealth.com'>WEGive@OhioHealth.com</a></div>
          </div>
          <img className='we-give-logo' src='https://www.ohiohealth.com/siteassets/campaigns/we-give/2021_wegive_graphic.jpg' alt='We Give Logo' />
        </div>
        }
      </div>
    )
  }
}

export default WeGiveApp
